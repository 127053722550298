h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
}

.btn {
    border: none;
}

.image-thumbnail {
    padding: 0px;
}